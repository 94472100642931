import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { CartItem, ShoppingOrderFormData, Discount, OrderCreateFormShopping } from "interfaces/front/page/orderForm";
import { withOrderNetworkError } from "lib/hoc/withOrderNetworkError";
import * as Yup from "yup";
import { array, object } from "yup";
import { onestopFlagSchema } from "lib/validation/onestopFlagSchema";
import { addressSchema } from "lib/validation/recipientSchemas";
import { Field, Formik, FormikProps } from "formik";
import { useFrontAuthContext } from "lib/hoc/withFrontAuth";
import CartAmountArea from "components/front/order/form/CartAmountArea";
import OrderFormSection from "components/front/order/form/OrderFormSection";
import PaymentField from "../payment/PaymentField";
import AddressField from "components/front/order/recipient/AddressField";
import DeliveryOptionField from "components/front/order/form/DeliveryOptionField";
import { ShoppingNote } from "components/front/order/literal/CrowdFundingNote";
import useCartAmountInfo, { CartAmountInfo } from "lib/hooks/useCartAmountInfo";
import { Confirm, Content } from "components/front/order/form/OrderForm";
import ShoppingOrderRealizeButtonArea from "components/front/order/form/ShoppingOrderRealizeButtonArea";
import EnhancedEcommerceContext from "lib/contexts/EnhancedEcommerceContext";
import useOrderItems from "lib/hooks/useOrderItems";
import OrderItemField from "components/front/order/form/fields/OrderItemField";
import useOrderFormOnSubmit from "lib/hooks/useOrderFormOnSubmit";
import useInitialPayment from "lib/hooks/useInitialPayment";
import { orderCourseOptionSchema } from "lib/validation/orderCourseOptionSchema";
import DiscountsField from "components/front/order/form/DiscountsField";

export type Props = { orderForm: ShoppingOrderFormData };
const OrderFormShopping: FC<Props> = ({ orderForm }) => {
  const { initialValues, validationSchema, onSubmit, isNeededRecipient, isDeliveryOption } = useOrderFormShoppingStates(orderForm);
  const isShopping = true;
  return (
    <Formik<OrderCreateFormShopping>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(formik) => (
        <OrderFormShoppingFieldset formik={formik} orderForm={orderForm}>
          {({ courseIds, cartAmountInfo, handleAddDiscount }) => (
            <Content>
              <OrderFormSection title="購入する商品を確認">
                {formik.values.order_items.map((_orderItem, index) => (
                  <OrderItemField key={index} name={`order_items[${index}]`} cartItems={orderForm.cart_items} project={orderForm.project} isShopping={isShopping} />
                ))}
                {formik.values.discounts.length === 0 && (
                  <DiscountsField name="discounts" projectId={orderForm.project.id} onAdd={handleAddDiscount} />
                )}
                <CartAmountArea info={cartAmountInfo} isShopping={isShopping} shippingFee={formik.values.delivery_method?.shipping_fee}/>
              </OrderFormSection>
              {cartAmountInfo.isNeededPayment && (
                <OrderFormSection title="お支払い情報の入力">
                  <Field
                    component={PaymentField}
                    name="payment"
                    totalAmount={cartAmountInfo.totalAmount}
                    supportEndAt={orderForm.project.support_end_at}
                    payment={orderForm.payment}
                  />
                </OrderFormSection>
              )}
              {isDeliveryOption && (
                orderForm.project.delivery_options.length !== 1 ? (
                  <OrderFormSection title="商品の受け取り方法を選択">
                    <Field component={DeliveryOptionField} name="delivery_method" delivery_options={orderForm.project} totalAmount={cartAmountInfo.totalAmount} />
                  </OrderFormSection>
                ) : (
                  // 受け取り方法が一つの場合、このエリアは非表示だがロジックは実行する
                  <Field component={DeliveryOptionField} name="delivery_method" delivery_options={orderForm.project} totalAmount={cartAmountInfo.totalAmount} />
                )
              )}
              {isNeededRecipient && (
                <OrderFormSection title="お届け先を入力">
                  <Field component={AddressField} name="recipient" courseIds={courseIds} />
                </OrderFormSection>
              )}
              <OrderFormSection title="購入前にご確認下さい">
                <Confirm>
                  <ShoppingNote />
                  <ShoppingOrderRealizeButtonArea />
                </Confirm>
              </OrderFormSection>
            </Content>
          )}
        </OrderFormShoppingFieldset>
      )}
    </Formik>
  );
};
export default withOrderNetworkError(OrderFormShopping);

const OrderFormShoppingFieldset: FC<{
  orderForm: ShoppingOrderFormData;
  formik: FormikProps<OrderCreateFormShopping>;
  children: FC<{
    courseIds: number[];
    cartAmountInfo: CartAmountInfo;
    handleAddDiscount: (discount: Discount) => void;
  }>;
}> = ({ orderForm, formik, children }) => {
  const { triggerOrderFormEvent, triggerPaymentEvent } = useContext(EnhancedEcommerceContext.OrderForm);
  const courseIds = useMemo(() => formik.values.order_items.map((orderItem) => orderItem.course_id), [formik.values.order_items]);
  const cartAmountInfo = useCartAmountInfo(orderForm.cart_items, orderForm.system_commission.amount, formik.values.discounts);
  const handleAddDiscount = useCallback(
    (discount: Discount) => {
      const willNotNeededPayment = cartAmountInfo.totalAmount <= discount.amount;
      if (willNotNeededPayment) formik.setFieldValue("payment", { method: "no_method" });
    },
    [cartAmountInfo.totalAmount, formik.setFieldValue]
  );
  useEffect(() => {
    triggerOrderFormEvent().catch(console.error);
  }, [triggerOrderFormEvent]);
  useEffect(() => {
    if (!cartAmountInfo.isNeededPayment) {
      formik.setFieldValue("payment", { method: "no_method" })
      triggerPaymentEvent("no_method").catch(console.error);
    }
  }, [cartAmountInfo.isNeededPayment, formik.setFieldValue, triggerPaymentEvent]);
  return <fieldset disabled={formik.isSubmitting}>{children({ courseIds, cartAmountInfo, handleAddDiscount })}</fieldset>;
};

const useOrderFormShoppingStates = (orderForm: ShoppingOrderFormData) => {
  const member = useFrontAuthContext();
  const isNeededRecipient = useMemo(
    () => orderForm.cart_items.some((cartItem: CartItem) => cartItem.course.delivery_flag),
    [orderForm.cart_items]
  );
  const isDeliveryOption = orderForm.project.delivery_options_flag;
  const orderItems = useOrderItems(orderForm.cart_items, member);
  const payment = useInitialPayment(orderForm.payment);
  const initialDeliveryMethod = useMemo(() => {
    const deliveryOptions = orderForm.project.delivery_options;
    if (deliveryOptions.length === 1) {
      const selectedOption = deliveryOptions[0];
      return {
        method: selectedOption.method,
        shipping_fee: undefined,
      };
    }
    return undefined;
  }, [orderForm.project.delivery_options]);
  const initialValues: OrderCreateFormShopping = {
    service_type: "shopping",
    order_items: orderItems,
    discounts: [],
    onestop_flag: null,
    recipient: isNeededRecipient ? orderForm.order_recipient : undefined,
    delivery_method: initialDeliveryMethod,
    payment: payment,
    writes_comment: false,
  };
  const validationSchema = useMemo(
    () =>
      Yup.object({
        order_items: array().of(
          object().shape({
            details: array().of(
              object().shape({
                course_options: array().defined().of(orderCourseOptionSchema()),
              })
            ),
          })
        ),
        onestop_flag: onestopFlagSchema(false, false),
        recipient: isNeededRecipient ? addressSchema : Yup.object().notRequired(),
        delivery_method: isDeliveryOption ? Yup.object().shape({
          method: Yup.string().required("配送方法を選択してください。"),
          shipping_fee: Yup.number().notRequired()
        }).required("配送オプションは必須です。") : Yup.mixed().notRequired(),
        payment: Yup.object().required("必須項目です。"),
        writes_comment: Yup.boolean().defined(),
      }).defined(),
    [isNeededRecipient, isDeliveryOption]
  );
  const onSubmit = useOrderFormOnSubmit(orderForm.project.code);
  return {
    isNeededRecipient,
    initialValues,
    validationSchema,
    isDeliveryOption,
    onSubmit,
  };
};
