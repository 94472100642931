import React, { useState, useEffect, FC } from "react";
import { FieldProps } from 'formik';
import styled from 'styled-components';
import { DeliveryOptions } from "interfaces/front/business";
import GrayBlock from "components/front/order/literal/GrayBlock";
import ErrorBlock from "components/front/order/literal/ErrorBlock";

export type OrderDeliveryMethod = {
  method: string;
  shipping_fee: number | undefined;
};

export type Props = FieldProps & {
  totalAmount: number;
  delivery_options: DeliveryOptions;
};

const DeliveryOptionField: FC<Props> = ({ field, form: { errors, setFieldValue }, totalAmount, delivery_options }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const deliveryOptionsArray = delivery_options.delivery_options ?? [];

  const MAX_FREE_SHIPPING_AMOUNT = 3300; // 送料が無料になる金額
  const SHIPPING_FEE = 880; // 加算される送料

  useEffect(() => {
    if (deliveryOptionsArray.length === 1) {
      const selectedOption = deliveryOptionsArray[0];
      const shippingFee = selectedOption.shipping_fee_flag && totalAmount < MAX_FREE_SHIPPING_AMOUNT ? SHIPPING_FEE : 0;
      setTimeout(() => {
        setFieldValue(field.name, {
          method: selectedOption.method,
          shipping_fee: shippingFee,
        });
      }, 0);
      if (shippingFee > 0) {
        setModalContent(`金額が${MAX_FREE_SHIPPING_AMOUNT}円未満のため、送料${SHIPPING_FEE}円が加算されました。`);
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  }, [deliveryOptionsArray, totalAmount, setFieldValue, field.name]);

  const handleDeliveryOptionChange = (selectedOption: string) => {
    const selectedDeliveryOption = deliveryOptionsArray.find(option => option.method === selectedOption);
    let shippingFee = 0;
    let message = '';
    if (selectedDeliveryOption && selectedDeliveryOption.shipping_fee_flag && totalAmount < MAX_FREE_SHIPPING_AMOUNT) {
      shippingFee = SHIPPING_FEE;
      message = `金額が${MAX_FREE_SHIPPING_AMOUNT}円未満のため、送料${SHIPPING_FEE}円が加算されました。`;
      setShowModal(true);
    } else if (field.value && field.value.shipping_fee) {
      message = '受け取り方法が変更されたため、送料の加算が取り消されました。';
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    setModalContent(message);
    const deliveryMethod: OrderDeliveryMethod = {
      method: selectedOption,
      shipping_fee: shippingFee,
    };
    setFieldValue(field.name, deliveryMethod);
  };
  return (
    <>
      <Container style={{ display: deliveryOptionsArray.length === 1 ? 'none' : undefined }}>
        {delivery_options.pickup_instruction && <GrayBlock>{delivery_options.pickup_instruction}</GrayBlock>}
        {errors[field.name] && <StyledErrorBlock>受け取り方法を選択してください</StyledErrorBlock>}
        <OptionsContainer>
          {deliveryOptionsArray.map((option, index) => (
            <Label key={index}>
              <input
                type="radio"
                name={field.name}
                value={option.method}
                checked={field.value && field.value.method === option.method}
                onChange={() => handleDeliveryOptionChange(option.method)}
              />
              {option.method}
            </Label>
          ))}
        </OptionsContainer>
      </Container>
      {showModal && (
        <ModalOverlay onClick={() => setShowModal(false)}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <ModalMessage>{modalContent}</ModalMessage>
            <ModalMessage>{"最新の金額をご確認ください。"}</ModalMessage>
            <ModalButton onClick={() => setShowModal(false)}>OK</ModalButton>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
};
export default DeliveryOptionField;

const StyledErrorBlock = styled(ErrorBlock)`
  margin: 14px 0;
`;
const Container = styled.div`
  margin-bottom: 15px;
  background-color: white;
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 90px;
  padding-top: 20px;

  @media screen and (max-width: 960px) {
    gap: 20px;
  }
`;
const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;

  @media screen and (max-width: 960px) {
    font-size: 14px;
  }

  input[type="radio"] {
    margin-right: 10px;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:checked {
      border: 2px solid #000;

      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #000;
      }
    }

    &:focus {
      outline: none;
    }
  }
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  
  @media (min-width: 1280px) {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
`;
const Modal = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 33px;
  position: fixed;
  max-width: 700px;
  width: 90%;
  text-align: center;
`;
const ModalMessage = styled.p`
  font-size: 18px;
  margin-bottom: 25px;
`;
const ModalButton = styled.button`
  background-color: #007bff;
  color: white;
  font-size: 18px;
  padding: 12px 22px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;
