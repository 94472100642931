import { FormikValues } from "formik";
import {
  Address,
  Aniale,
  Avatar,
  Content,
  CorporateAddress,
  CourseServiceType,
  CvsDetail,
  CvsType,
  DeliveryProperty,
  DonationCourse,
  Member,
  OrderDetail as BusinessOrderDetail,
  OrderProjectRequisite,
  OrderRecipient,
  OrderStatus,
  Payment,
  Project,
  Residence,
  ResidentGender,
  RewardCourse,
  SupportComment,
  SystemCommission,
  OrderDeliveryMethod,
} from "interfaces/front/business";
import { OrderListOutput } from "interfaces/front/OrderListOutput";
import { goErrorPage, goLoginPage } from "lib/frontRouting";
import { NextRouter } from "next/router";
import { BaseClientService, ErrorV2, Failure, Result, Success, ValidationErrorV2 } from "lib/service/BaseClientService";
import { PaymentSetting } from "interfaces/front/page/orderForm";

export class FrontOrderService extends BaseClientService {
  url: string;
  goLoginPage: () => Promise<void>;
  goErrorPage: () => Promise<void>;

  constructor(router: NextRouter) {
    super();
    this.url = this.API_BASE_URL + "/api/front/orders";
    this.goLoginPage = async (): Promise<void> => {
      await goLoginPage(router);
    };
    this.goErrorPage = async (): Promise<void> => {
      await goErrorPage(router);
    };
  }

  async list(): Promise<Result<OrderListOutput, ErrorV2>> {
    const response = await fetch(this.url, {
      credentials: "include",
      method: "GET",
      headers: this.headers,
    });
    if (response.ok) {
      return new Success(await response.json());
    } else if (response.status == 401) {
      await this.goLoginPage();
      return new Failure({ error: "login error" });
    } else {
      await this.goErrorPage();
      return new Failure({ error: "error" });
    }
  }

  async get(orderCode: string, onSuccess: (order: OrderGetOutput) => void): Promise<OrderGetOutput | null> {
    const response = await fetch(`${this.url}/${orderCode}`, {
      credentials: "include",
      method: "GET",
      headers: this.headers,
    });
    if (response.ok) {
      const order = (await response.json()) as OrderGetOutput;
      onSuccess(order);
      return order;
    } else if (response.status == 401) {
      await this.goLoginPage();
      return null;
    } else {
      await this.goErrorPage();
      return null;
    }
  }

  async getTippingForm(projectId: number | string): Promise<Result<TippingFormOutput, ErrorV2>> {
    const url = this.buildUrl(`${this.url}/tipping_form`, { project_id: projectId });
    return this.doSearch(url);
  }

  async order(form: OrderCreateInput): Promise<Result<OrderCreateOutput, ErrorV2>> {
    return await this.doPost(this.url + "/create", JSON.stringify(form));
  }

  async orderAsTipping(form: AnialeOrderInput): Promise<Result<TippingOutput, ErrorV2>> {
    let paymentForm: Payment;
    if (form.payment.method === "credit_card") {
      paymentForm = {
        method: "credit_card",
        credit_card: {
          onetime_token: {
            token: form.payment.onetime_token,
            token_key: form.payment.onetime_token_key,
          },
        },
      };
    } else if (form.payment.method === "credit_card_reuse") {
      paymentForm = { method: "credit_card", credit_card: { reuse: {} } };
    } else if (form.payment.method === "cvs") {
      paymentForm = {
        method: "cvs",
        cvs: {
          cvs_type: form.payment.cvs_type,
          phone_number: form.payment.phone_number,
        },
      };
    } else {
      return new Failure<TippingOutput, ErrorV2>({
        error: "アニエールができませんでした。",
      });
    }
    const serverForm: ServerTippingOrderInput =
      form.serviceType === "hometown_tax"
        ? {
            project_code: form.projectCode,
            service_type: form.serviceType,
            amount: form.amount!!,
            comment: form.comment,
            course_options: [],
            onestop_flag: form.onestopFlag,
            recipient: form.recipient,
            payment: paymentForm,
          }
        : {
            project_code: form.projectCode,
            service_type: form.serviceType,
            amount: form.amount!!,
            comment: form.comment,
            course_options: [],
            payment: paymentForm,
          };
    return this.doPost(`${this.url}/tipping`, JSON.stringify(serverForm));
  }

  async updateRecipient2(
    orderCode: string,
    recipient: Address | Residence | CorporateAddress
  ): Promise<Result<UpdateRecipientOutput, ErrorV2>> {
    return this.doPut(`${this.url}/${orderCode}`, "recipient", JSON.stringify(recipient));
  }

  async updateComment(
    orderCode: string,
    query: UpdateCommentQuery,
    onSuccess: () => void,
    onValidationError: (error: ValidationErrorV2) => void
  ): Promise<void> {
    const response = await fetch(`${this.url}/${orderCode}/support_comment`, {
      credentials: "include",
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(query),
    });
    if (response.ok) {
      await onSuccess();
    } else if (response.status == 401) {
      await this.goLoginPage();
    } else if (response.status == 400) {
      const error = (await response.json()) as ErrorV2;
      await onValidationError(error.validation_error || {});
    } else {
      await this.goErrorPage();
    }
  }

  async updateCourseOption(
    orderCode: string,
    query: UpdateCourseOptionInput,
    onSuccess: () => void,
    onError: (error: ErrorV2) => void
  ): Promise<void> {
    const response = await fetch(`${this.url}/${orderCode}/course_option`, {
      credentials: "include",
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(query),
    });
    if (response.ok) {
      await onSuccess();
    } else if (response.status == 400) {
      const error = (await response.json()) as ErrorV2;
      await onError(error || {});
    } else if (response.status == 401) {
      await this.goLoginPage();
    } else {
      await this.goErrorPage();
    }
  }
}

export interface OrderCreateInput {
  details: CourseOrderDetail[];
  onestop_flag: boolean | null;
  recipient?: OrderRecipient | CorporateAddress;
  delivery_method?: OrderDeliveryMethod;
  payment: Payment | undefined;
  writes_comment: boolean;
  service_type: CourseServiceType;
}

export type CourseOrderDetail = {
  course_id: number;
  amount: number;
  course_options: CourseOptionInput[];
};

export interface OrderCreateOutput {
  order_code: string;
  sent_mail: boolean;
}

export type UpdateCourseOptionInput = FormikValues & {
  detail_id: number;
  question: string;
  id: number;
  value: string;
};

export type UpdateRecipientOutput = {
  alert_sent: string;
};

export interface CourseOptionInput {
  id: string;
  value: string;
}

export type AnialeOrderInput = AnialeOrderInputCrowdFunding | AnialeOrderInputHometownTax;
export type AnialeOrderInputCrowdFunding = AnialeFormCrowdFundingData;
export type AnialeOrderInputHometownTax = AnialeFormHometownTaxData;

export type AnialeFormCrowdFundingData = {
  projectCode: string;
  serviceType: "crowd_funding";
  amount: number | undefined;
  comment: string;
  payment: AnialePayment;
};
export type AnialeFormHometownTaxData = {
  projectCode: string;
  serviceType: "hometown_tax";
  amount: number | undefined;
  comment: string;
  payment: AnialePayment;
  onestopFlag: boolean | null;
  recipient: Residence;
};

export type AnialePayment = AnialeCreditCardParameter | AnialeCreditCardReuseParameter | AnialeCvsParameter;
export type AnialeCreditCardParameter = {
  method: "credit_card";
  onetime_token: string;
  onetime_token_key: string;
};
export type AnialeCreditCardReuseParameter = {
  method: "credit_card_reuse";
};
export type AnialeCvsParameter = {
  method: "cvs";
  cvs_type: CvsType;
  phone_number: string;
};

export interface ServerTippingOrderInput {
  project_code: string;
  service_type: CourseServiceType;
  course_options: CourseOptionInput[];
  payment: Payment;
  recipient?: OrderRecipient;
  onestop_flag?: boolean | null;
  amount: number;
  comment: string;
}

export interface OrderResult {
  order_code: string;
  sent_mail: boolean;
}

export interface TippingOutput extends OrderResult {
  presented_avatars: Avatar[];
}

export type UpdateCommentQuery = {
  comment: string;
};

export interface TippingFormOutput {
  order_recipient: OrderRecipient;
  payment: PaymentSetting;
}

export type OrderGetOutput = {
  id: number;
  order_type: "support" | "tipping";
  code: string;
  status: OrderStatus;
  download_count: number;
  member: Member;
  order_recipient: OrderAddress | OrderResidence | OrderCorporateAddress | null;
  order_discounts: OrderDiscount[];
  order_payment: OrderPayment;
  support_comment: SupportComment | null;
  support_comment_template: string;
  system_commission: SystemCommission | null;
  order_project_requisite: OrderProjectRequisite | null;
  ordered_at: string;
  order_details: (Omit<OrderDetail, "course"> & {
    course: OrderRewardCourse | Omit<DonationCourse | Aniale, "tipping_setting" | "tipping_amount_conditions">;
  })[];
  order_delivery_method: OrderDeliveryMethod;
  project: Pick<Project, "code" | "service_type" | "support_end_at"| "delivery_options"> & {
    content: Pick<Content, "twitter_hashtag1" | "twitter_hashtag2">;
  };
};

export type OrderPayment =
  | {
      payment_method: "credit_card";
      amount: number;
      cvs_detail: null;
      bank_detail: null;
    }
  | {
      payment_method: "cvs";
      amount: number;
      cvs_detail: CvsDetail;
      bank_detail: null;
    }
  | {
      payment_method: "bank";
      amount: number;
      cvs_detail: null;
      bank_detail: {
        bank: string;
        branch: string;
        type: string;
        number: string;
        holder: string;
      };
    }
  | {
      payment_method: "no_method";
      amount: number;
      cvs_detail: null;
      bank_detail: null;
    };

interface OrderDiscount {
  discount_name: string;
  discount_amount: number;
}

export type OrderDetail = Omit<BusinessOrderDetail, "course"> & {
  course:
    | Omit<RewardCourse, "course_returns" | "course_options">
    | Omit<DonationCourse | Aniale, "tipping_setting" | "tipping_amount_conditions">;
};
export type Course =
  | Omit<RewardCourse, "course_returns" | "course_options">
  | Omit<DonationCourse | Aniale, "tipping_setting" | "tipping_amount_conditions">;

type OrderRewardCourse = Omit<RewardCourse, "course_returns" | "course_options"> & {
  delivery_property: DeliveryProperty;
};

interface OrderAddress {
  type: "address";
  name_sei: string;
  name_mei: string;
  kana_sei: string;
  kana_mei: string;
  phone_number: string;
  zip_code: string;
  prefectures: string;
  city: string;
  house_number: string;
  building_name: string;
  gender: null;
  birthday: null;
  can_change: boolean;
}

interface OrderResidence {
  type: "residence";
  name_sei: string;
  name_mei: string;
  kana_sei: string;
  kana_mei: string;
  phone_number: string;
  zip_code: string;
  prefectures: string;
  city: string;
  house_number: string;
  building_name: string;
  gender: ResidentGender;
  birthday: string;
  can_change: boolean;
}

interface OrderCorporateAddress {
  type: "corporate_address";
  name_sei: string;
  name_mei: string;
  kana_sei: string;
  kana_mei: string;
  phone_number: string;
  zip_code: string;
  prefectures: string;
  city: string;
  house_number: string;
  building_name: string;
  gender: null;
  birthday: null;
  corporate_name: string;
  corporate_department: string;
  can_change: boolean;
}
